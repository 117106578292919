<template>
  <div
    class="flex flex-shrink-0 items-center font-light cursor-pointer px-1.5 py-1 select-none dark:bg-yb-gray-alt-dark dark:border-yb-gray-alt-lightest-inverse dark:hover:border-yb-brand-primary overflow-hidden"
    :class="classes"
    @click="!disabled && $emit('click')"
  >
    <yb-icon class="icon-type flex-none flex-shrink-0 yb-button-icon mr-1.5" :class="base" :icon="icon" />
    <div class="flex-shrink-0">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      required: true,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  emits: ['click'],
  computed: {
    base() {
      return this.disabled ? 'opacity-50 cursor-not-allowed' : ''
    },
    classes() {
      const { base } = this
      return base + ' text-sm border border-yb-gray-lighter rounded-md ' + (this.disabled ? '' : 'hover:bg-yb-gray-fainter')
    }
  }
})
</script>
